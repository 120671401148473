import React, { useState, useEffect, createContext, ReactElement, ReactNode } from 'react'
//import { signin } from 'services/Auth'
import SecureLS from 'secure-ls'
import User from 'models/User'
import Api from 'api/Api'

const ls = new SecureLS({ encodingType: 'aes' })

type CanOptions = {
  resource: string
  resourceId?: number
  action: string
}

export interface IAuthContext {
  currentUser: User | null
  Can: (options: CanOptions) => boolean
  signin: (email: string, password: string) => void
  signout: () => void
}

interface ProviderProps {
  children: ReactNode
}

export const AuthContext = createContext<IAuthContext | null>(null)

const AuthProvider = ({ children }: ProviderProps): ReactElement => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)

  useEffect(() => {
    checkUser()
  }, [currentUser])

  const signin = async (email: string, password: string) => {
    const { data } = await Api.post('/auth', { email, password })
    const user = new User(data.user)
    ls.set('token', JSON.stringify(data.token))
    ls.set('currentUser', JSON.stringify(user))
    setCurrentUser(user)
  }

  const signout = () => {
    setCurrentUser(null)
    ls.removeAll()
  }

  const checkUser = () => {
    let user = null
    const auth = ls.get('currentUser')
    if (auth !== '') {
      user = new User(JSON.parse(auth))
      if (!currentUser) setCurrentUser(user)
      else if (user.id !== currentUser.id) setCurrentUser(user)
    }
  }

  const Can = (options: CanOptions): boolean => {
    const { resource, resourceId, action } = options
    if (currentUser) {
      if (resource && action) {
        let index = -1
        if (resourceId) {
          index = currentUser.permissions.findIndex(
            item => item.resource === resource && item.action === action && item.resourceId === resourceId,
          )
        } else {
          index = currentUser.permissions.findIndex(item => item.resource === resource && item.action === action)
        }
        return currentUser.role.name === 'Administrador' || index >= 0
      }
    }
    return false
  }

  return <AuthContext.Provider value={{ currentUser, Can, signin, signout }}>{children}</AuthContext.Provider>
}

export default AuthProvider
