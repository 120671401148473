import React, { useEffect, useState, useContext, Dispatch, SetStateAction, ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faFile, faTimes } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loader-spinner'
import FileService from '../../services/FileService'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import InnerImageZoom from 'react-inner-image-zoom'
import { toast } from 'react-toastify'
import { AuthContext, IAuthContext } from '../../context/useAuth'
import { addWaterMark } from '../../helpers/Image'
import { IPage } from 'models/Page'
import LibraryService from 'services/LibraryService'
import PrintPage from 'views/Books/PrintPage'

export function PreviewModal({
  preview,
  setPreview,
}: {
  preview: string
  setPreview: Dispatch<SetStateAction<string | undefined>>
}) {
  const { Can, currentUser } = useContext(AuthContext) as IAuthContext
  const [loading, setLoading] = useState(false)
  const [loadingThumbs, setLoadingThumbs] = useState(false)
  const [data, setData] = useState<string | undefined>()
  const [thumbnails, setThumbnails] = useState<IPage[]>([])
  const [page, setPage] = useState<IPage | undefined>()
  const [src, setSrc] = useState<string>('')

  const [disablePrev, setDisablePrev] = useState(false)
  const [disableNext, setDisableNext] = useState(false)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    loadPage()
  }, [preview])

  useEffect(() => {
    if (page) {
      loadThumbnails()
      loadImage()
    }
  }, [page])

  const loadPage = async () => {
    const page = await LibraryService.getImageByKey(preview)
    setPage(page)
  }

  const loadThumbnails = async () => {
    let previewElements = preview.split('/')
    previewElements = previewElements.slice(0, previewElements.length - 1)

    const total = await LibraryService.countPagesLocation(previewElements.join('/'))
    setTotal(total)

    if (!page) return

    const thumbnail = thumbnails.find(el => el.id === page.id)

    if (thumbnail) return

    try {
      setLoadingThumbs(true)
      const thumbs = await LibraryService.getThumbnails(page.id)

      const init = thumbs.find(el => el.name.includes('-' + '1'.padStart(total.toString().length, '0')))
      if (init) setDisablePrev(true)

      const end = thumbs.find(el => el.name.includes('-' + total.toString().padStart(total.toString().length, '0')))
      if (end) setDisableNext(true)

      setThumbnails([])
      setThumbnails(thumbs)
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingThumbs(false)
    }
  }

  const getNextThumbnails = async () => {
    setDisablePrev(false)
    try {
      setLoadingThumbs(true)
      const thumbs = await LibraryService.getNextThumbnails(thumbnails[thumbnails.length - 1].id)

      const end = thumbs.find(el => el.name.includes('-' + total.toString().padStart(total.toString().length, '0')))
      if (end) setDisableNext(true)

      if (thumbs.length) {
        setThumbnails([])
        setThumbnails(thumbs)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingThumbs(false)
    }
  }

  const getPrevThumbnails = async () => {
    setDisableNext(false)
    try {
      setLoadingThumbs(true)
      const thumbs = await LibraryService.getPrevThumbnails(thumbnails[0].id)

      const init = thumbs.find(el => el.name.includes('-' + '1'.padStart(total.toString().length, '0')))
      if (init) setDisablePrev(true)

      if (thumbs.length) {
        setThumbnails([])
        setThumbnails(thumbs)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingThumbs(false)
    }
  }

  const generateWaterMark = () => {
    if (currentUser) return currentUser.waterMark()
    else return ['NO COPIAR']
  }

  const loadImage = () => {
    if (!page) return

    setLoading(true)

    const url = FileService.getImageUrl(page.key)

    fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
          }),
      )
      .then(async base64 => {
        setSrc(base64 as string)
        const data = await addWaterMark(base64 as string, generateWaterMark())
        setData(data)
      })
      .catch(e => {
        console.error('error', e)
        toast.error('Error!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handlePrev = async () => {
    if (!page) return

    const index = thumbnails.findIndex(el => el.name == page.name)

    if (index == 0) {
      const image = await LibraryService.getPrevImage(thumbnails[0].id)
      if (image) setPage(image)
    } else if (index > -1) {
      setPage(thumbnails[index - 1])
    } else {
      const previewElements = page.key.split('/')
      const previewName = previewElements[previewElements.length - 1]
      const newPage = parseInt(previewName.replace('-', '').replace('.jpg', '')) - 1
      const pageString = newPage + '.jpg'

      const newPreview = `${previewElements.slice(0, previewElements.length - 1).join('/')}/${pageString}`
      setPreview(newPreview)
    }
  }

  const handleNext = async () => {
    if (!page) return

    const index = thumbnails.findIndex(el => el.name == page.name)

    if (index > -1 && index == thumbnails.length - 1) {
      const image = await LibraryService.getNextImage(thumbnails[thumbnails.length - 1].id)
      if (image) setPage(image)
    } else if (index > -1) {
      setPage(thumbnails[index + 1])
    } else {
      const previewElements = page.key.split('/')
      const previewName = previewElements[previewElements.length - 1]
      const newPage = parseInt(previewName.replace('-', '').replace('.jpg', '')) + 1
      const pageString = newPage + '.jpg'

      const newPreview = `${previewElements.slice(0, previewElements.length - 1).join('/')}/${pageString}`
      setPreview(newPreview)
    }
  }

  return (
    <Modal
      show={preview !== undefined}
      onHide={() => setPreview(undefined)}
      backdrop='static'
      keyboard={false}
      animation={false}
      dialogClassName='modal-90w'
    >
      <Modal.Body style={{ backgroundColor: '#555' }}>
        <Row>
          <Col
            sm={12}
            xs={12}
            style={{
              paddingTop: 20,
            }}
          >
            <Row className='justify-content-center'>
              <Col xs={1} sm={1}>
                {disablePrev || (
                  <Button className='btn-trans btn-circle btn-icon btn-hover-mint' onClick={getPrevThumbnails}>
                    <i className='icon-2x'>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </i>
                  </Button>
                )}
              </Col>
              {loadingThumbs ? (
                <Col xs={10} sm={10} className='text-center'>
                  <div className='text-center'>
                    <Loader type='TailSpin' color='#00BFFF' />
                  </div>
                </Col>
              ) : (
                thumbnails.map(thumb => (
                  <Col
                    xs={1}
                    sm={1}
                    key={thumb.id}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => setPage(thumb)}
                  >
                    <div
                      className='media-block text-center'
                      style={{
                        color: 'white',
                        backgroundColor: page?.name === thumb.name ? '#294F75' : 'transparent',
                        paddingTop: 8,
                      }}
                    >
                      <div>
                        <i>
                          <FontAwesomeIcon icon={faFile} size='4x' />
                        </i>
                      </div>
                      <div className='media-body'>
                        <p
                          className='file-name'
                          style={{
                            color: 'white',
                          }}
                        >
                          {thumb.name}
                        </p>
                      </div>
                    </div>
                  </Col>
                ))
              )}
              <Col xs={1} sm={1}>
                {disableNext || (
                  <Button className='btn-trans btn-circle btn-icon btn-hover-mint' onClick={getNextThumbnails}>
                    <i className='icon-2x'>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </i>
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
          <Col sm={12} xs={12}>
            <div className='ng-arrow-left'>
              {page?.name.includes('0001') || (
                <Button className='btn-trans btn-circle btn-icon btn-hover-mint' onClick={handlePrev}>
                  <i className='icon-2x'>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </i>
                </Button>
              )}
            </div>
            <div className='ng-arrow-right'>
              {page?.name.includes(total.toString().padStart(4, '0')) || (
                <Button className='btn-trans btn-circle btn-icon btn-hover-mint' onClick={handleNext}>
                  <i className='icon-2x'>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Button>
              )}
            </div>

            <Row>
              <Col sm={12}>
                <div className='text-center'>
                  <Button
                    className='btn-trans btn-circle btn-icon btn-hover-mint'
                    onClick={() => setPreview(undefined)}
                  >
                    <i className='icon-2x'>
                      <FontAwesomeIcon icon={faTimes} />
                    </i>
                  </Button>
                  {Can({ resource: 'Book', action: 'print' }) && page && (
                    <PrintPage page={0} bookName={page.name} src={src} />
                  )}
                  {loading && (
                    <div className='text-center'>
                      <Loader type='TailSpin' color='#00BFFF' />
                    </div>
                  )}
                  {loading ||
                    (data && (
                      <div key={page?.id}>
                        <h4 className='text-light'>{page?.name}</h4>
                        <div onContextMenu={e => e.preventDefault()}>
                          <InnerImageZoom src={data} zoomSrc={data} />
                        </div>
                      </div>
                    ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
