import React, { useEffect, useState, Dispatch, SetStateAction, ReactElement } from 'react'
import S3Service from '../../services/S3Service'
import { Modal, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import FileService from 'services/FileService'
import LocalService from 'services/LocalService'

export const FileModal = ({
  show,
  setShow,
  path,
}: {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  path: string
}): ReactElement => {
  const [files, setFiles] = useState<FileList | null>()
  const [loading, setLoading] = useState(false)

  const upload = async (files: FileList | undefined | null) => {
    if (!files) return
    try {
      setLoading(true)
      await FileService.uploadFiles(files, path)
      toast.success('Te enviaremos un correo cuando la carga este lista')
    } catch (e) {
      console.error(e)

      if (e.error) {
        toast.error(`${e.error}`)
      } else {
        toast.error('Error! :(')
      }
    } finally {
      setLoading(false)
      setShow(false)
    }
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} backdrop='static' keyboard={false} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Subir Archivos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          type='file'
          multiple
          className='form-control input-lg'
          onChange={e => setFiles(e.target.files)}
          accept='.pdf'
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setShow(false)} disabled={loading}>
          Cerrar
        </Button>
        <Button variant='info' onClick={() => upload(files)} disabled={loading}>
          {loading ? 'Subiendo...' : 'Subir'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const FolderModal = ({
  show,
  setShow,
  path,
  folderCreated,
}: {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  path: string
  folderCreated: (folder: string) => void
}): ReactElement => {
  const [folder, setFolder] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFolder('')
  }, [show])

  const uploadFolder = async () => {
    try {
      setLoading(true)
      const fullFolder = `${path ? `${path}/` : path}${folder}`
      await FileService.uploadFolder(fullFolder)
      folderCreated(`${fullFolder}/`)
      toast.success('Carpeta creada exitosamente')
      setShow(false)
    } catch (e) {
      console.error(e)

      if (e.error) {
        toast.error(`${e.error}`)
      } else {
        toast.error('Error! :(')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} backdrop='static' keyboard={false} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Crear carpeta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          type='text'
          placeholder='Nombre de la carpeta'
          defaultValue={folder}
          onChange={e => setFolder(e.target.value)}
          className='form-control input-lg'
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setShow(false)}>
          Cerrar
        </Button>
        <Button variant='info' onClick={() => uploadFolder()} disabled={loading}>
          {loading ? 'Creando...' : 'Crear'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
