import React, { useState, useEffect, useRef, ReactElement, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimesCircle, faImages, faCopy } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loader-spinner'
import splitFrames, { imageProcess, imageProcessS3 } from 'helpers/Image'
import { BookContext, IBookContext } from 'context/bookContext'
import { AuthContext, IAuthContext } from 'context/useAuth'
import Canvas from './Canvas'
import PrintPage from './PrintPage'
import BookPage from 'models/BookPage'
import 'assets/css/image_preview.min.css'
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import FileService from 'services/FileService'

type RowProps = {
  piecesRow: Array<ImageData>
}

type StateProps = {
  pageIndex: number
}

declare class ClipboardItem {
  constructor(data: { [mimeType: string]: Blob })
}

interface Clipboard {
  writeText(newClipText: string): Promise<void>
  write(clipboardData: any): Promise<void>
}

interface NavigatorClipboard {
  // Only available in a secure context.
  readonly clipboard?: Clipboard
}

const Detail = (): ReactElement => {
  const { search, bookPages, updatePage } = useContext(BookContext) as IBookContext
  const { Can, currentUser } = useContext(AuthContext) as IAuthContext
  const history = useHistory()
  const { state } = useLocation<StateProps>()
  const [pieces, setPieces] = useState(new Array<Array<ImageData>>(0))
  const [showModal, setShowModal] = useState(false)
  const [book, setBook] = useState<null | BookPage>(null)
  const previewRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState('')

  useEffect(() => {
    if (state) {
      const pageFinded = bookPages[state.pageIndex]
      if (pageFinded) getImageData(pageFinded)
    }
  }, [state])

  const generateWaterMark = () => {
    if (currentUser) return currentUser.waterMark()
    return ['NO COPIAR']
  }

  const getImageData = async (bookPage: BookPage) => {
    setLoading(true)

    try {
      const url = FileService.getImageUrl(bookPage.imagePath)

      fetch(url)
        .then(response => response.blob())
        .then(
          blob =>
            new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onloadend = () => resolve(reader.result)
              reader.onerror = reject
              reader.readAsDataURL(blob)
            }),
        )
        .then(async base64 => {
          setData(base64 as string)

          const { imageData, imageDataPreview, url } = await imageProcessS3(
            base64 as string,
            bookPage.coincidencesByWord,
            bookPage.coincidencesExact,
            generateWaterMark(),
          )

          bookPage.imageData = imageData
          bookPage.imageDataPreview = imageDataPreview
          setBook(bookPage)
          if (state && state.pageIndex) updatePage(state.pageIndex, bookPage)
          if (imageDataPreview) getPieces(imageDataPreview)
          if (url) setData(url)
        })
        .catch(e => {
          console.error('error', e)
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (e) {
      console.error('error get ImageData: ', e)
    }
  }

  const RowPiece = ({ piecesRow }: RowProps) => {
    return (
      <div className='no-mar-between'>
        {piecesRow.map((piece, key) => (
          <Canvas key={key} dataImage={piece} />
        ))}
      </div>
    )
  }

  const getPieces = async (data: ImageData) => {
    if (previewRef && previewRef.current) {
      const divWidth = ~~(previewRef.current.offsetWidth * 0.8)
      const piecestmp = await splitFrames(data, divWidth)
      setPieces(piecestmp)
    }
  }

  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)

  const handleShowLibrary = () => {
    if (book) {
      const imagePath = book.imagePath
      let folderPath: Array<string> = imagePath.split('/')
      folderPath = folderPath.slice(0, folderPath.length - 1)

      history.push({
        pathname: '/library',
        state: {
          folderPath: folderPath.join('/'),
          imageName: imagePath,
        },
      })
    }
  }

  return (
    <div className='panel'>
      <div className='panel-body'>
        <Button className='btn-mint' onClick={history.goBack}>
          Regresar
        </Button>
        <div className='pad-btm form-inline'>
          <div className='row'>
            <div className='col-sm-6 table-toolbar-left'>
              <h3>
                <small>Frase Buscada: </small>
                <span className='mark'> {search}</span>
                <small> Pág. </small>
                {book ? book.pageNumber : ''}
              </h3>
            </div>
            <div className='col-sm-6 table-toolbar-right'>
              {Can({ resource: 'Library', action: 'show' }) && book && (
                <Button className='btn-labeled mar-rgt' onClick={handleShowLibrary}>
                  <i className='btn-label'>
                    <FontAwesomeIcon icon={faImages} size='lg' />
                  </i>
                  Ver en Biblioteca
                </Button>
              )}
              {Can({ resource: 'Book', action: 'print' }) && book && (
                <PrintPage imageData={book.imageData} page={book.pageNumber} bookName={book.bookName} />
              )}
            </div>
          </div>
        </div>

        {loading && (
          <div className='text-center'>
            <Loader type='TailSpin' color='#00BFFF' />
            <p className='text-bold'>Cargando imagen, espere ... </p>
          </div>
        )}

        <div onContextMenu={e => e.preventDefault()}>
          <InnerImageZoom src={data} zoomSrc={data} />
        </div>
      </div>
    </div>
  )
}

export default Detail
