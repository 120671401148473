import Role from './Role'
import UserPermission from './UserPermission'
import UserDepartment from './UserDepartment'

interface UserProperties {
  id?: number
  email?: string
  firstName?: string
  lastName?: string
  motherLastName?: string
  role?: Role
  blocked?: boolean
  password?: string
  permissions?: Array<UserPermission>
  departments?: Array<UserDepartment>
}

interface UserDataExport {
  firstName: string
  lastName: string
  motherLastName: string
  email: string
  blocked: boolean
  role: string
  password?: string
}

export default class User {
  id
  email
  firstName
  lastName
  motherLastName
  role
  blocked
  password = ''
  permissions = new Array<UserPermission>(0)
  departments = new Array<UserDepartment>(0)

  constructor(properties: UserProperties = {}) {
    this.id = properties.id || 0
    this.firstName = properties.firstName || ''
    this.email = properties.email || ''
    this.lastName = properties.lastName || ''
    this.motherLastName = properties.motherLastName || ''
    this.role = properties.role || new Role()
    this.blocked = properties.blocked || false
    if (properties.permissions)
      this.permissions = properties.permissions.map((item: UserPermission) => new UserPermission(item))
    if (properties.departments)
      this.departments = properties.departments.map((item: UserDepartment) => new UserDepartment(item))
  }
  fullName(): string {
    return `${this.firstName} ${this.lastName} ${this.motherLastName}`
  }
  shortName(): string {
    return `${this.firstName} ${this.lastName}`
  }
  waterMark(): Array<string> {
    return [this.firstName, `${this.lastName} ${this.motherLastName}`]
  }
  asJSON(): UserDataExport {
    const data: UserDataExport = {
      firstName: this.firstName,
      lastName: this.lastName,
      motherLastName: this.motherLastName,
      email: this.email,
      blocked: this.blocked,
      role: this.role.name,
    }
    if (this.password.length > 0) data['password'] = this.password
    return data
  }

  isAdmin() {
    return this.role.name === 'Administrador'
  }
}

export const UserHeaders = ['Correo Electrónico', 'Nombre', 'Rol', 'Status', 'Acciones']
