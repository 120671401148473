import React, { ReactElement, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Form, Col, Button, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import UserService from 'services/UserService'
import User from 'models/User'
import UserPermission from 'models/UserPermission'

import { notify } from 'components/Toast/Toast'

interface RouteParams {
  userId: string
}
const Permissions = (): ReactElement => {
  const { register, handleSubmit } = useForm()
  const { userId } = useParams<RouteParams>()
  const [user, setUser] = useState<User | null>(null)
  const history = useHistory()

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await UserService.getUser(+userId)
        setUser(user)
      } catch (e) {
        if (e.error) notify('error', e.error)
        console.log(e)
      }
    }
    getUser()
  }, [userId])

  const permissionExist = (resource: string, action: string) => {
    let index = -1
    if (user) {
      index = user.permissions.findIndex(item => item.resource === resource && item.action === action)
    }
    return index >= 0
  }

  const onFormSubmit = (values: { search: boolean; print: boolean; show_library: boolean; modify: boolean }) => {
    if (user) {
      const userPermissions = []
      if (values.search) {
        userPermissions.push(
          new UserPermission({
            resource: 'Book',
            action: 'search',
          }),
        )
      }
      if (values.print) {
        userPermissions.push(
          new UserPermission({
            resource: 'Book',
            action: 'print',
          }),
        )
      }
      if (values.show_library) {
        userPermissions.push(
          new UserPermission({
            resource: 'Library',
            action: 'show',
          }),
        )
      }
      if (values.modify) {
        userPermissions.push(
          new UserPermission({
            resource: 'Library',
            action: 'modify',
          }),
        )
      }
      savePermissions(user.id, userPermissions)
    }
  }

  const savePermissions = async (userId: number, permissions: Array<UserPermission>) => {
    try {
      const message = await UserService.saveUserPermissions(userId, permissions)
      notify('', message)
    } catch (e) {
      console.log('Error save user permissions : ', e)
      if (e.error) notify('error', e.error)
    }
  }

  return (
    <Row>
      <div className='col-md-6 col-md-offset-3'>
        {user && (
          <div className='panel'>
            <div className='panel-heading'>
              <h3 className='panel-title'>{user.fullName()}</h3>
            </div>
            <Form onSubmit={handleSubmit(onFormSubmit)} className='form-horizontal form-padding'>
              <div className='panel-body'>
                <Form.Group>
                  <Form.Label column sm='3'></Form.Label>
                  <Col md={9}>
                    <div className='checkbox'>
                      <input
                        id='search'
                        className='magic-checkbox'
                        type='checkbox'
                        name='search'
                        ref={register}
                        defaultChecked={permissionExist('Book', 'search')}
                      />
                      <label htmlFor='search'>Realizar Busquedas</label>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label column sm='3'></Form.Label>
                  <Col md={9}>
                    <div className='checkbox'>
                      <input
                        id='print'
                        className='magic-checkbox'
                        type='checkbox'
                        name='print'
                        ref={register}
                        defaultChecked={permissionExist('Book', 'print')}
                      />
                      <label htmlFor='print'>Imprimir</label>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label column sm='3'></Form.Label>
                  <Col md={9}>
                    <div className='checkbox'>
                      <input
                        id='show_library'
                        className='magic-checkbox'
                        type='checkbox'
                        name='show_library'
                        ref={register}
                        defaultChecked={permissionExist('Library', 'show')}
                      />
                      <label htmlFor='show_library'>Biblioteca</label>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label column sm='3'></Form.Label>
                  <Col md={9}>
                    <div className='checkbox'>
                      <input
                        id='modify'
                        className='magic-checkbox'
                        type='checkbox'
                        name='modify'
                        ref={register}
                        defaultChecked={permissionExist('Library', 'modify')}
                      />
                      <label htmlFor='modify'>Modificar biblioteca</label>
                    </div>
                  </Col>
                </Form.Group>
              </div>
              <div className='panel-footer text-right'>
                <Button variant='default' onClick={() => history.push('/users')}>
                  Cancelar
                </Button>
                &nbsp;
                <Button type='submit'>Actualizar Permisos</Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </Row>
  )
}

export default Permissions
