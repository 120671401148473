import React, { ReactElement } from 'react'
import AuthProvider from 'context/useAuth'
import { BrowserRouter as Router } from 'react-router-dom'
import Notifier from 'components/Toast/Toast'
import BaseLayout from 'views/layouts/BaseLayout'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'

const App = (): ReactElement => (
  <Router>
    <Notifier />
    <AuthProvider>
      <BaseLayout />
    </AuthProvider>
    <ToastContainer />
  </Router>
)

export default App
