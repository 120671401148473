import Users from 'views/Users'
import Home from 'views/Home/index'
import AdvancedSearch from 'views/Home/advanced'
import NotFound from 'views/NotFound'
import Permissions from 'views/Users/Permissions'
import Departments from 'views/Users/Departments'
import UserHistory from 'views/Users/History'
import PageDetails from 'views/Books/Details'
import PageDetailsVolume from 'views/Books/PageDetailsVolume'
import VolumeDetail from 'views/Books/VolumeDetail'
import Library from 'views/Library'

const Routes = [
  {
    path: '/users/:userId/permissions',
    name: 'Permisos de Usuario',
    component: Permissions,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/users/:userId/departments',
    name: 'Departamentos del usuario',
    component: Departments,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/users/history',
    name: 'Historial',
    component: UserHistory,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/users',
    name: 'Usuarios',
    component: Users,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/details',
    name: 'Detalle de Página',
    component: PageDetails,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/details/page/book',
    name: 'Detalle de Página',
    component: PageDetailsVolume,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/book/details',
    name: 'Detalle de Libro',
    component: VolumeDetail,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/library',
    name: 'Biblioteca',
    component: Library,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/',
    name: 'Busqueda',
    component: Home,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/advanced',
    name: 'Busqueda Compuesta',
    component: AdvancedSearch,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/404',
    component: NotFound,
    isPrivate: true,
  },
]

export default Routes
