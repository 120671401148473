import { IFolder } from 'models/Folder'

interface IUserDepartment {
  folder?: IFolder
}

export default class UserDepartment {
  folder: IFolder
  constructor(properties: IUserDepartment = {}) {
    this.folder = properties.folder || {
      id: 0,
      name: '',
      key: '',
      location: '',
      isFile: false,
      isAnalized: false,
    }
  }
}
