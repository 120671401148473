import Api from '../api/Api'
import SecureLS from 'secure-ls'
import { environment } from 'Enviroments'
import S3Service from './S3Service'
import LocalService from './LocalService'

const resource = '/s3'
const ls = new SecureLS({ encodingType: 'aes' })

export default class FileService {
  static getImageUrl(image: string) {
    if (environment.isS3) {
      return S3Service.getImageUrl(image)
    } else {
      return LocalService.getImageUrl(image)
    }
  }

  static async uploadFiles(files: FileList, path: string) {
    if (environment.isS3) {
      return S3Service.uploadFiles(files, path)
    } else {
      return LocalService.uploadFiles(files, path)
    }
  }

  static async uploadFolder(folder: string) {
    if (environment.isS3) {
      return S3Service.uploadFolder(folder)
    } else {
      return LocalService.uploadFolder(folder)
    }
  }
}
