import React, { ReactElement, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Volume } from '../../models/Volume'

interface IBook {
  index: number
  volume: Volume
}

const VolumeComponent = ({ index, volume }: IBook): ReactElement => {
  const history = useHistory()

  const goDetails = () => {
    history.push({
      pathname: `/book/details`,
      state: { bookName: volume.name },
    })
  }

  return (
    <Fragment>
      <Col sm={6}>
        <div className={`panel bg-gray bord-all bord-lft-${volume.matches > 0 ? 'success' : 'warning'}`}>
          <div className='panel-body'>
            <div className='media bord-btm'>
              <div className='media-left'>
                <FontAwesomeIcon icon={faFileAlt} size='lg' />
              </div>
              <div className='media-body pad-btm'>
                <p className='text-lg'>Titúlo de Libro</p>
                <h4 className='mar-no text-main'>{volume.name}</h4>
              </div>
            </div>
            <div className='row pad-top'>
              <div className='list-group bg-trans mar-no pad-hor'>
                <p className='text-main'>
                  Coincidencias por palabra
                  <span className='pull-right text-bold'>{volume.matches}</span>
                </p>
              </div>
            </div>
            <div className='row text-center'>
              <Col sm={12}>
                <button onClick={goDetails} className='btn btn-primary btn-labeled'>
                  <i className='btn-label'>
                    <FontAwesomeIcon icon={faInfoCircle} size='lg' />
                  </i>
                  Revisar documento
                </button>
              </Col>
            </div>
          </div>
        </div>
      </Col>
    </Fragment>
  )
}

export default VolumeComponent
