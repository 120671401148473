import React, { useRef, useEffect, ReactElement } from 'react'

type CanvasProps = {
  dataImage?: ImageData | null
  src?: string
}

const Canvas = ({ dataImage, src = '' }: CanvasProps): ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const context = canvas.getContext('2d')
      if (dataImage) {
        const context = canvas.getContext('2d')
        canvas.width = dataImage.width
        canvas.height = dataImage.height
        if (context) context.putImageData(dataImage, 0, 0)
      } else {
        const image = new Image()
        image.onload = () => {
          if (context) context.drawImage(image, 0, 0)
        }
        image.src = src
      }
    }
  }, [dataImage, src])

  return <canvas ref={canvasRef} />
}

export default Canvas
