import Api from '../api/Api'
import SecureLS from 'secure-ls'
import { environment } from 'Enviroments'

const resource = '/s3'
const ls = new SecureLS({ encodingType: 'aes' })

export default class S3Service {
  static getImageUrl(image: string) {
    return `${environment.url}${resource}/image?image=${image}`
  }

  static async uploadFiles(files: FileList, path: string) {
    const formData = new FormData()
    for (let x = 0; x < files.length; x++) formData.append('files', files[x])
    formData.append('path', path)
    const AUTH_TOKEN = ls.get('token')
    await Api.post(`${resource}/upload_files`, formData, {
      headers: {
        Authorization: JSON.parse(AUTH_TOKEN),
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  static async uploadFolder(folder: string) {
    const { data } = await Api.post(`${resource}/folder`, {
      folder,
    })

    return data
  }
}
