import React, { useEffect, useState, ReactElement, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Button, Dropdown } from 'react-bootstrap'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserEdit, faUserPlus, faUserTimes, faUserShield } from '@fortawesome/free-solid-svg-icons'
import { notify } from 'components/Toast/Toast'
import NiftyTable from 'components/Table/NiftyTable'
import Pagination from 'components/Pagination/Pagination'
import ConfirmModal from 'components/Modal/Confirm'

import User, { UserHeaders } from 'models/User'
import UserService from 'services/UserService'
import UserForm from './form'
import UserSearch from './search'

const Users = (): ReactElement => {
  const history = useHistory()
  const [users, setUsers] = useState(new Array<User>(0))
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [user, setUser] = useState(new User())
  const [loading, setLoading] = useState(false)

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [deleteUserId, setDeleteUserId] = useState(0)

  //pagination
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalUsers, setTotalUsers] = useState(0)

  useEffect(() => {
    getUsers(page, perPage, search)
  }, [page, perPage, search])

  const handlePageChange = (newPage: number) => setPage(newPage)

  const handlePerPage = (newPerPage: number) => setPerPage(newPerPage)

  const getUsers = async (page: number, perPage: number, search: string) => {
    setLoading(true)
    try {
      const data = await UserService.getUsers(page, perPage, search)
      const usersData = data.users.map((item: User) => new User(item))
      setUsers(usersData)
      setPerPage(+perPage)
      setPage(+data.pagination.current_page)
      setTotalUsers(+data.pagination.total_elements)
    } catch (e) {
      console.log('Error getting Users:: ', e)
      if (e.error) notify('error', e.error)
    }
    setLoading(false)
  }

  const handleEdit = async (id: number) => {
    try {
      const data = await UserService.getUser(id)
      setUser(data)
      setShowModal(true)
    } catch (e) {
      console.log('Error getting user: ', e)
      if (e.error) notify('error', e.error)
    }
  }

  const handleDelete = (id: number) => {
    setDeleteUserId(id)
    setConfirmOpen(true)
  }

  const deleteUser = async () => {
    try {
      const message = await UserService.deleteUser(deleteUserId)
      setConfirmOpen(false)
      setDeleteUserId(0)
      getUsers(page, perPage, search)
      notify('', message)
    } catch (e) {
      console.log('Error delete user: ', e)
      if (e.error) notify('error', e.error)
    }
  }

  const handleSearch = (tag: string) => setSearch(tag)

  const save = async (user: User) => {
    try {
      const message = await UserService.saveUser(user)
      closeModal()
      notify('', message)
      getUsers(page, perPage, search)
    } catch (e) {
      console.log('Error save user: ', e)
      if (e.error) notify('error', e.error)
    }
  }

  const closeModal = () => {
    setShowModal(false)
    setUser(new User())
  }

  const body = () => {
    return (
      <Fragment>
        {users.map((user, key) => {
          return (
            <tr key={key}>
              <td>{user.email}</td>
              <td>{user.fullName()}</td>
              <td>{user.role.name}</td>
              <td>
                <span className={`label label-table label-${user.blocked ? 'danger' : 'success'}`}>
                  {user.blocked ? 'Bloqueado' : 'Activo'}
                </span>
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle className='btn-mint btn-active-mint'>
                    Opciones <i className='dropdown-caret'></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu as='ul'>
                    <li>
                      <Dropdown.Item onClick={() => handleEdit(user.id)}>
                        <FontAwesomeIcon icon={faUserEdit} size='lg' className='icon-fw' />
                        Editar Usuario
                      </Dropdown.Item>
                    </li>
                    <li>
                      <Dropdown.Item onClick={() => history.push(`/users/${user.id}/permissions`)}>
                        <FontAwesomeIcon icon={faUserShield} size='lg' className='icon-fw' />
                        Permisos
                      </Dropdown.Item>
                    </li>
                    <li>
                      <Dropdown.Item onClick={() => history.push(`/users/${user.id}/departments`)}>
                        <FontAwesomeIcon icon={faUserShield} size='lg' className='icon-fw' />
                        Departamentos
                      </Dropdown.Item>
                    </li>
                    <li>
                      <Dropdown.Item onClick={() => handleDelete(user.id)}>
                        <FontAwesomeIcon icon={faUserTimes} size='lg' className='icon-fw' />
                        Eliminar Usuario
                      </Dropdown.Item>
                    </li>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          )
        })}
      </Fragment>
    )
  }

  return (
    <div className='panel'>
      <div className='panel-body'>
        <div className='pad-btm form-inline'>
          <Row>
            <Col sm={6} className='table-toolbar-left'>
              <Button onClick={() => setShowModal(true)}>
                <i className='icon-fw'>
                  <FontAwesomeIcon icon={faUserPlus} />
                </i>
                Agregar Usuario
              </Button>
            </Col>
            <Col sm={6} className='table-toolbar-right'>
              <UserSearch onSubmit={handleSearch} defaultSearch={search} />
            </Col>
          </Row>
        </div>
        {loading && (
          <div className='text-center'>
            <Loader type='TailSpin' color='#00BFFF' />
          </div>
        )}
        <NiftyTable headers={UserHeaders} dataBody={body()} />
        <Pagination
          totalRecords={totalUsers}
          pageLimit={perPage}
          currentPage={page}
          changePage={handlePageChange}
          changePageLimit={handlePerPage}
        />

        <UserForm user={user} onSubmit={save} onShow={showModal} onClose={closeModal} />

        <ConfirmModal
          title='Eliminar Usuario'
          show={confirmOpen}
          handleClose={() => setConfirmOpen(false)}
          onSave={deleteUser}
        >
          <p>Esta seguro de eliminar este usuario.</p>
        </ConfirmModal>
      </div>
    </div>
  )
}
export default Users
