import React, { useState, ReactNode, ReactElement } from 'react'
import { notify } from '../components/Toast/Toast'
import BookService from '../services/BookService'
import { Volume } from '../models/Volume'
import BookPage from 'models/BookPage'

export interface ILibraryContext {
  search: string
  searchFilter: string
  volumes: Array<Volume>
  page: number
  perPage: number
  total: number

  pageBooks: number
  totalBookPages: number
  bookPages: Array<BookPage>
  perPageBooks: number
  updateBookPages: (bookName: string, page: number, perPage: number) => void
  updatePage: (index: number, bookPage: BookPage) => void
  clearSearchBooks: () => void

  updateVolumes: (searchTag: string, searchFilter: string, page: number, perPage: number) => void
  updateVolume: (bookName: string) => void
  clearSearch: () => void
}

type LibraryProps = {
  children: ReactNode
}

export const LibraryContext = React.createContext<ILibraryContext | null>(null)

const LibraryProvider = ({ children }: LibraryProps): ReactElement => {
  const [volumes, setVolumes] = useState(new Array<Volume>(0))
  const [search, setSearch] = useState('')
  const [searchFilter, setSearchFilter] = useState('all')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(0)

  const [pageBooks, setPageBooks] = useState(1)
  const [totalBookPages, setTotalBookPages] = useState(0)
  const [perPageBooks, setPerPageBooks] = useState(10)
  const [bookPages, setBookPages] = useState<Array<BookPage>>([])

  const updateBookPages = async (bookName: string, page: number, perPage: number) => {
    try {
      const data = await BookService.book(search, bookName, page, perPage)
      setPerPageBooks(perPage)
      setTotalBookPages(data.pagination.total_elements)
      setBookPages(data.bookPages)
      setPageBooks(page)
    } catch (e) {
      console.log('error: ', e)
      if (e.error) notify('error', e.error)
    }
  }

  const updatePage = (index: number, bookPage: BookPage) => {
    const pages = [...bookPages]
    pages[index] = bookPage
    setBookPages(pages)
  }

  const updateVolume = async (bookName: string) => {
    await updateBookPages(bookName, 1, 10)
  }

  const clearSearchBooks = () => {
    setPageBooks(1)
    setBookPages([])
    setTotalBookPages(0)
  }

  const updateVolumes = async (searchTag: string, searchFilter: string, page: number, perPage: number) => {
    try {
      if (searchTag.length > 0) {
        const data = await BookService.searchVolume(searchTag, searchFilter, page, perPage)
        setVolumes(data.volumes)
        setTotal(data.pagination.total_elements)
        setSearch(searchTag)
        setSearchFilter(searchFilter)
        setPage(page)
        setPerPage(perPage)
      } else setSearchFilter(searchFilter)
    } catch (e) {
      console.log('error: ', e)
      if (e.error) notify('error', e.error)
    }
  }

  const clearSearch = () => {
    setSearch('')
    setVolumes([])
    setPage(1)
    setPerPage(10)
    setTotal(0)
  }

  return (
    <LibraryContext.Provider
      value={{
        search,
        searchFilter,
        page,
        perPage,
        total,
        volumes,
        updateVolumes,
        updateVolume,
        clearSearch,

        pageBooks,
        perPageBooks,
        totalBookPages,
        bookPages,
        updateBookPages,
        clearSearchBooks,
        updatePage,
      }}
    >
      {children}
    </LibraryContext.Provider>
  )
}

export default LibraryProvider
