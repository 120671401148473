import React, { useEffect, useState, ReactElement, useContext, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload, faFolderPlus } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loader-spinner'
import { Row, Button, Pagination } from 'react-bootstrap'
import { AuthContext, IAuthContext } from 'context/useAuth'

import { FolderBack, FolderElement } from '../../components/Library/FolderElement'
import { PageElement } from '../../components/Library/PageElement'
import { FileModal, FolderModal } from '../../components/Library/Modals'
import { PreviewModal } from '../../components/Library/PreviewModal'
import LibraryService from 'services/LibraryService'
import { IFolder } from 'models/Folder'
import { IPage } from 'models/Page'

type StateProps = {
  folderPath: string
  imageName: string
}

const Library = (): ReactElement => {
  const { Can, currentUser } = useContext(AuthContext) as IAuthContext
  const { state } = useLocation<StateProps>()

  const [loading, setLoading] = useState(false)
  const [create, setCreate] = useState(false)
  const [createFolder, setCreateFolder] = useState(false)

  const [breadCrumbs, setBreadCrumbs] = useState<Array<string>>([])
  const [preview, setPreview] = useState<string | undefined>(state && state.imageName ? state.imageName : undefined)
  const [path, setPath] = useState(state && state.folderPath ? state.folderPath : '')
  const [folders, setFolders] = useState<IFolder[]>([])
  const [pages, setPages] = useState<IPage[]>([])
  const [pending, setPending] = useState<Array<IFolder>>([])

  const limit = 100
  const [active, setActive] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    let page = 0
    if (preview && preview.includes(path)) {
      const elementsPreview = preview.split('/')
      const previewName = elementsPreview[elementsPreview.length - 1]
      page = Math.floor(parseInt(previewName.slice(0, 4)) / limit)
    }

    const isAdmin = currentUser?.isAdmin()
    if (isAdmin && path === '') getPending()
    getFiles(page)
    configBreadCrumbs()
  }, [path])

  const configBreadCrumbs = () => setBreadCrumbs(path.split('/'))

  const getPending = async () => {
    try {
      const pending = await LibraryService.getPending()
      setPending(pending)
    } catch (e) {
      console.error(e)
    }
  }

  const getFiles = async (page = 0) => {
    try {
      setLoading(true)

      const data = await LibraryService.get(path, limit, page * limit)

      setFolders(data.folders)
      setPages(data.pages)

      setTotal(Math.ceil(data.total / limit))
      setActive(page)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const enterFolder = (folder: IFolder) => {
    setPath(`${folder.key}`)
  }

  const navigate = (index: number) => {
    setPath(breadCrumbs.slice(0, index + 1).join('/'))
  }

  const goBack = () => {
    const index = breadCrumbs.length - 2
    if (index === -1) {
      setPath('')
    } else {
      navigate(index)
    }
  }

  const addFolder = (folder: string) => {
    //todo
    //folders.push({ Prefix: folder })
    //setFolders([...folders])
  }

  return (
    <div className='panel'>
      <div className='panel-body'>
        <div className='pad-all file-manager'>
          <div className='fixed-fluid'>
            <div className='fixed-sm-200 pull-sm-left file-sidebar'>
              <p className='pad-hor mar-top text-main text-bold text-sm text-uppercase'>Biblioteca</p>
              {Can({ resource: 'Library', action: 'modify' }) && (
                <Fragment>
                  <Button onClick={() => setCreate(true)} className='btn-labeled' style={{ marginBottom: '5px' }}>
                    <i className='btn-label'>
                      <FontAwesomeIcon icon={faFileUpload} size='lg' />
                    </i>
                    Subir archivos
                  </Button>
                  <Button onClick={() => setCreateFolder(true)} className='btn-labeled' style={{ marginBottom: '5px' }}>
                    <i className='btn-label'>
                      <FontAwesomeIcon icon={faFolderPlus} size='lg' />
                    </i>
                    Crear carpeta
                  </Button>
                </Fragment>
              )}
              {
                //Can({ resource: 'Book', action: 'print' }) && <PrintPage page={0} bookName='name' images={images} />
              }
            </div>
            <div className='fluid file-panel'>
              <div className='bord-btm pad-ver'>
                <ol className='breadcrumb'>
                  <li key={`rootpath`}>
                    <a className='file-details' onClick={() => setPath('')}>
                      root
                    </a>
                  </li>
                  {breadCrumbs.map((el, key) => (
                    <li key={`${key}path`}>
                      <a className='file-details' onClick={() => navigate(key)}>
                        {el}
                      </a>
                    </li>
                  ))}
                </ol>
              </div>
              <div>
                <div className='nano-content'>
                  <div className='input-group mar-btm' style={{ paddingTop: '18px' }}></div>
                  <ul id='demo-mail-list' className='file-list'>
                    <Row>
                      {loading && (
                        <div className='text-center'>
                          <Loader type='TailSpin' color='#00BFFF' />
                        </div>
                      )}
                      {breadCrumbs.length && breadCrumbs[0] != '' ? (
                        <FolderBack key={`goBackFolder`} onClick={goBack} />
                      ) : (
                        <></>
                      )}
                      {loading ||
                        folders.map((folder, key) => (
                          <FolderElement key={`${key}folders${folder.key}`} folder={folder} enterFolder={enterFolder} />
                        ))}
                      {loading ||
                        pages.map((page, key) => (
                          <PageElement key={`${key}file${page.key}`} page={page} setPreview={setPreview} />
                        ))}
                    </Row>
                  </ul>

                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Pagination>
                      <Pagination.First onClick={() => getFiles(0)} />
                      <Pagination.Prev
                        onClick={() => {
                          if (active != 0) getFiles(active - 1)
                        }}
                      />
                      {Array.from(Array(total).keys()).map(index => (
                        <Pagination.Item key={`${index}page`} active={index === active} onClick={() => getFiles(index)}>
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() => {
                          if (active != total - 1) getFiles(active + 1)
                        }}
                      />
                      <Pagination.Last onClick={() => getFiles(total - 1)} />
                    </Pagination>
                  </div>

                  {currentUser?.isAdmin() && path === '' && (
                    <div>
                      <div className='h5'>Archivos pendientes por analizar: {pending.length}</div>
                      <ul>
                        {pending.map((folder, index) => {
                          return <li key={`notAnalized${index}`}>{folder.name}</li>
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          <FileModal path={path} setShow={setCreate} show={create} />
          <FolderModal path={path} setShow={setCreateFolder} show={createFolder} folderCreated={addFolder} />
          {preview && <PreviewModal key={`${preview}`} preview={preview} setPreview={setPreview} />}
        </>
      </div>
    </div>
  )
}

export default Library
