import Api from 'api/Api'
import BookPage from 'models/BookPage'
import { Volume } from 'models/Volume'
const resource = '/books'

interface IPagesData {
  bookPages: Array<BookPage>
  pagination: Pagination
}

interface IVolumesData {
  volumes: Array<Volume>
  pagination: Pagination
}

interface Pagination {
  total_elements: number
}

interface IImageData {
  type: string
  data: Buffer
}

export default class BookService {
  static async search(search: string, searchFilter: string, page: number, perPage: number): Promise<IPagesData> {
    const url = `${resource}?page=${page}&page_size=${perPage}&search=${search}&search_filter=${searchFilter}`
    const { data } = await Api.get(url)
    const bookPages = new Array<BookPage>(0)
    const pagination = data.pagination
    for (const page of data.books) {
      const bookPage = new BookPage({
        bookName: page.book,
        pageNumber: page.page,
        coincidencesExact: page.coincidencesExact,
        coincidencesByWord: page.coincidencesByWord,
        imagePath: page.imagePath,
      })
      bookPages.push(bookPage)
    }
    return { bookPages, pagination }
  }

  static async searchVolume(
    search: string,
    searchFilter: string,
    page: number,
    perPage: number,
  ): Promise<IVolumesData> {
    const url = `${resource}/advanced?page=${page}&page_size=${perPage}&search=${search}&search_filter=${searchFilter}`
    const { data } = await Api.get(url)
    const pagination = data.pagination
    return { volumes: data.volumes, pagination }
  }

  static async book(search: string, book: string, page: number, perPage: number): Promise<IPagesData> {
    const url = `${resource}/book?page=${page}&page_size=${perPage}&search=${search}&book=${book}`
    const { data } = await Api.get(url)
    const pagination = data.pagination
    return { bookPages: data.books, pagination }
  }
}
