export interface IRect {
  Height: number
  Width: number
  Top: number
  Left: number
}

export interface IBookPage {
  bookName: string
  pageNumber: number
  coincidencesExact: Array<IRect>
  coincidencesByWord: Array<IRect>
  imageData?: ImageData | null
  imageDataPreview?: ImageData | null
  imagePath: string
}

export default class BookPage {
  bookName
  pageNumber
  coincidencesExact
  coincidencesByWord
  imageData
  imageDataPreview
  imagePath
  constructor(properties: IBookPage) {
    this.bookName = properties.bookName || 'Libro sin Nombre'
    this.pageNumber = properties.pageNumber || 0
    this.coincidencesExact = properties.coincidencesExact || new Array<IRect>(0)
    this.coincidencesByWord = properties.coincidencesByWord || new Array<IRect>(0)
    this.imageData = properties.imageData || null
    this.imageDataPreview = properties.imageDataPreview || null
    this.imagePath = properties.imagePath || ''
  }

  async resizeImage(imageData: ImageData): Promise<ImageData> {
    let resizeWidth = 0
    let resizeHeight = 0

    const windowWidth = ~~(window.innerWidth * 0.8)
    const windowHeight = ~~(window.innerHeight * 0.8)
    const imageRatio = imageData.height / imageData.width
    const pageRatio = windowHeight / windowWidth

    const fixHeight = pageRatio > imageRatio
    if (fixHeight) {
      resizeHeight = windowHeight
      resizeWidth = ~~(windowHeight / imageRatio)
    } else {
      resizeHeight = ~~(windowWidth * imageRatio) //imageData.height //
      resizeWidth = windowWidth //imageData.width //
    }
    const ibm = await window.createImageBitmap(imageData, 0, 0, imageData.width, imageData.height, {
      resizeWidth,
      resizeHeight,
    })
    const canvas = document.createElement('canvas')
    canvas.width = resizeWidth
    canvas.height = resizeHeight
    const ctx = canvas.getContext('2d')
    if (ctx) {
      //ctx.scale(resizeWidth / imageData.width, resizeHeight / imageData.height)
      ctx.drawImage(ibm, 0, 0)
      ctx.save()
      ctx.translate(100, 300)
      ctx.rotate(-0.3 * Math.PI)
      const text = 'NO COPIAR'
      ctx.font = '50pt Times New Roman'
      ctx.fillStyle = '#a94442'
      ctx.textAlign = 'center'
      ctx.fillText(text, 0, 50)
      ctx.restore()
      ctx.scale(resizeWidth / imageData.width, resizeHeight / imageData.height)
      const imageDataResize = ctx.getImageData(0, 0, resizeWidth, resizeHeight)
      return imageDataResize
    }
    return imageData
  }
}
