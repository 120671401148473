import React, { useState, useContext, ReactElement, Fragment } from 'react'
import { notify } from 'components/Toast/Toast'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loader-spinner'
import VolumeComponent from 'views/Books/Volume'
import Pagination from 'components/Pagination/Pagination'

import { LibraryContext, ILibraryContext } from 'context/libraryContext'
import { AuthContext, IAuthContext } from 'context/useAuth'

const AdvancedSearch = (): ReactElement => {
  const { register, handleSubmit, errors, setValue } = useForm()
  const { search, searchFilter, page, perPage, total, volumes, updateVolumes, clearSearch } = useContext(
    LibraryContext,
  ) as ILibraryContext
  const { Can } = useContext(AuthContext) as IAuthContext
  const [loading, setLoading] = useState(false)

  const onFormSubmit = async (values: { search: string }) => {
    setLoading(true)
    try {
      await updateVolumes(values.search, searchFilter, 1, perPage)
    } catch (e) {
      console.log(e)
      if (e instanceof TypeError) notify('error', e.message)
    }
    setLoading(false)
  }

  const handlePageChange = async (newPage: number) => {
    setLoading(true)
    await updateVolumes(search, searchFilter, newPage, perPage)
    setLoading(false)
  }

  const handlePerPage = async (newPerPage: number) => {
    setLoading(true)
    await updateVolumes(search, searchFilter, page, newPerPage)
    setLoading(false)
  }

  const handleSearchFilter = async (filter: string) => {
    setLoading(true)
    await updateVolumes(search, filter, page, perPage)
    setLoading(false)
  }

  const handleClearSearch = () => {
    setValue('search', '')
    clearSearch()
  }

  const handleMouseDown = (event: React.MouseEvent) => event.stopPropagation()

  return (
    <Fragment>
      {Can({ resource: 'Book', action: 'search' }) && (
        <div className='row pad-btm'>
          <Form onSubmit={handleSubmit(onFormSubmit)} className='col-xs-12 col-sm-10 col-sm-offset-1 pad-hor'>
            <Form.Group className={errors.search ? 'has-warning' : ''}>
              <div className='input-group mar-btm'>
                <input
                  name='search'
                  ref={register({ required: true })}
                  type='text'
                  placeholder='Buscar...'
                  defaultValue={search}
                  className='form-control input-lg'
                />
                <span className='input-group-btn'>
                  <Button size='lg' as='input' type='submit' value='Buscar' />
                </span>
              </div>
              {errors.search && (
                <label className='control-label'>Ingresa al menos 1 caracteres para realizar una busqueda</label>
              )}
            </Form.Group>
          </Form>
        </div>
      )}

      <div className='panel'>
        <div className='panel-body'>
          {search.trim().length > 0 && (
            <div className='pad-hor mar-top pad-btm'>
              <Row>
                <div className='col-sm-11'>
                  <h2 className='text-thin mar-no'>
                    Se encontró {total} resultado{total > 1 ? 's' : ''} para:
                    <i className='text-info text-normal'> &ldquo;{search}&ldquo;</i>
                  </h2>
                </div>
                <div className='col-sm-1'>
                  <Button onClick={handleClearSearch}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </div>
              </Row>
              <hr />
            </div>
          )}
          {loading && (
            <div className='text-center'>
              <Loader type='TailSpin' color='#00BFFF' />
            </div>
          )}
          {!loading && (
            <Row>
              {volumes.map((volume, index) => (
                <VolumeComponent index={index} volume={volume} key={index} />
              ))}
            </Row>
          )}
          <Row>
            <Pagination
              totalRecords={total}
              pageLimit={perPage}
              currentPage={page}
              changePage={handlePageChange}
              changePageLimit={handlePerPage}
            />
          </Row>
        </div>
      </div>
    </Fragment>
  )
}

export default AdvancedSearch

/*
<Page
                  key={index}
                  index={index}
                  bookName={page.bookName}
                  pageNumber={page.pageNumber}
                  coincidencesExact={page.coincidencesExact.length}
                  coincidencesByWord={page.coincidencesByWord.length}
                />

*/
