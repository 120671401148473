import React, { useState, ReactElement, Fragment } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import Header from './Header'
import Main from './Main'
import Sidebar from './Sidebar'
import Footer from './Footer'
import Login from 'views/Login'

const BaseLayout = (): ReactElement => {
  const [showNav, setShowNav] = useState(true)

  const isLogin = useRouteMatch({ path: '/login', exact: true })

  const handleChangeShowNav = () => {
    setShowNav(!showNav)
  }
  return (
    <Fragment>
      {!isLogin && (
        <div id='container' className='effect aside-float aside-bright mainnav-lg'>
          <Header changeNav={handleChangeShowNav} />
          <div className='boxed'>
            <Sidebar />
            <Main />
          </div>
          <Footer />
        </div>
      )}
      {isLogin && <Route path='/login' component={Login} />}
    </Fragment>
  )
}

export default BaseLayout
