import React, { Dispatch, SetStateAction } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { Col } from 'react-bootstrap'
import { IPage } from 'models/Page'

export function PageElement({
  page,
  setPreview,
}: {
  page: IPage
  setPreview: Dispatch<SetStateAction<string | undefined>>
}) {
  return (
    <Col xs={12} sm={6} md={4} lg={3} xl={2} style={{ padding: 10 }}>
      <a onClick={() => setPreview(page.key)} className='file-details'>
        <div className='media-block text-center'>
          <div>
            <i>
              <FontAwesomeIcon icon={faFile} size='4x' />
            </i>
          </div>
          <div className='media-body'>
            <p className='file-name'>{page.name}</p>
          </div>
        </div>
      </a>
    </Col>
  )
}
