import React, { useState, useEffect, useContext, ReactElement, Fragment } from 'react'
import { notify } from 'components/Toast/Toast'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loader-spinner'
import PageDetail from 'views/Books/PageDetail'
import Pagination from 'components/Pagination/Pagination'

import { AuthContext, IAuthContext } from 'context/useAuth'
import { ILibraryContext, LibraryContext } from 'context/libraryContext'
import { useLocation, useHistory } from 'react-router-dom'

type StateProps = {
  bookName: string
}

const Home = (): ReactElement => {
  const { register, handleSubmit, errors, setValue } = useForm()
  const { state } = useLocation<StateProps>()
  const [bookName, setBookName] = useState('')
  const history = useHistory()
  // const { search, searchFilter, page, perPage, totalBookPages, bookPages, updateBookPages, clearSearch } = useContext(
  //   LibraryContext,
  // ) as ILibraryContext

  const {
    updateVolume,
    search,
    pageBooks,
    perPageBooks,
    totalBookPages,
    bookPages,
    updateBookPages,
    clearSearchBooks,
  } = useContext(LibraryContext) as ILibraryContext

  const { Can } = useContext(AuthContext) as IAuthContext
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (state) {
      setBookName(state.bookName)
    }
  }, [state])

  useEffect(() => {
    if (bookName) {
      changeBookName(bookName)
    }
  }, [bookName])

  const changeBookName = async (bookName: string) => {
    setLoading(true)
    await updateVolume(bookName)
    setLoading(false)
  }

  const handlePageChange = async (newPage: number) => {
    setLoading(true)
    await updateBookPages(bookName, newPage, perPageBooks)
    setLoading(false)
  }

  const handlePerPage = async (newPerPage: number) => {
    setLoading(true)
    await updateBookPages(bookName, pageBooks, newPerPage)
    setLoading(false)
  }

  const handleMouseDown = (event: React.MouseEvent) => event.stopPropagation()

  return (
    <Fragment>
      <div className='panel'>
        <div className='panel-body'>
          <Button className='btn-mint' onClick={history.goBack}>
            Regresar
          </Button>
          <div className='pad-hor mar-top pad-btm'>
            <Row>
              <div className='col-sm-11'>
                <h2 className='text-thin mar-no'>
                  Se encontró {totalBookPages} resultado{totalBookPages > 1 ? 's' : ''} para:
                  <i className='text-info text-normal'> &ldquo;{search}&ldquo;</i>
                </h2>
              </div>
            </Row>
            <hr />
          </div>

          {loading && (
            <div className='text-center'>
              <Loader type='TailSpin' color='#00BFFF' />
            </div>
          )}
          {!loading && (
            <Row>
              {bookPages.map((page, index) => (
                <PageDetail
                  key={index}
                  index={index}
                  bookName={bookName}
                  pageNumber={page.pageNumber}
                  coincidencesExact={page.coincidencesExact.length}
                  coincidencesByWord={page.coincidencesByWord.length}
                />
              ))}
            </Row>
          )}
          <Row>
            <Pagination
              totalRecords={totalBookPages}
              pageLimit={perPageBooks}
              currentPage={pageBooks}
              changePage={handlePageChange}
              changePageLimit={handlePerPage}
            />
          </Row>
        </div>
      </div>
    </Fragment>
  )
}

export default Home
