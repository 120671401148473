import Api from 'api/Api'
import { IFolder } from 'models/Folder'
import SecureLS from 'secure-ls'

const resource = '/departments'
const ls = new SecureLS({ encodingType: 'aes' })

export default class DepartmentService {
  static async get(): Promise<IFolder[]> {
    const { data } = await Api.get(`${resource}`)
    return data.departments
  }

  static async assign(departments: Array<IFolder>, userId: number): Promise<void> {
    await Api.put(`${resource}/user/${userId}`, departments)
  }
}
