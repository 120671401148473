import React, { useState, useContext, ReactElement, Fragment } from 'react'
import { notify } from 'components/Toast/Toast'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loader-spinner'
import Page from 'views/Books/Page'
import Pagination from 'components/Pagination/Pagination'

import { BookContext, IBookContext } from 'context/bookContext'
import { AuthContext, IAuthContext } from 'context/useAuth'

const Home = (): ReactElement => {
  const { register, handleSubmit, errors, setValue } = useForm()
  const { search, searchFilter, page, perPage, totalBookPages, bookPages, updateBookPages, clearSearch } = useContext(
    BookContext,
  ) as IBookContext
  const { Can } = useContext(AuthContext) as IAuthContext
  const [loading, setLoading] = useState(false)

  const onFormSubmit = async (values: { search: string }) => {
    setLoading(true)
    try {
      await updateBookPages(values.search, searchFilter, 1, perPage)
    } catch (e) {
      console.log(e)
      if (e instanceof TypeError) notify('error', e.message)
    }
    setLoading(false)
  }

  const handlePageChange = async (newPage: number) => {
    setLoading(true)
    await updateBookPages(search, searchFilter, newPage, perPage)
    setLoading(false)
  }

  const handlePerPage = async (newPerPage: number) => {
    setLoading(true)
    await updateBookPages(search, searchFilter, page, newPerPage)
    setLoading(false)
  }

  const handleSearchFilter = async (filter: string) => {
    setLoading(true)
    await updateBookPages(search, filter, page, perPage)
    setLoading(false)
  }

  const handleClearSearch = () => {
    setValue('search', '')
    clearSearch()
  }

  const handleMouseDown = (event: React.MouseEvent) => event.stopPropagation()

  return (
    <Fragment>
      {Can({ resource: 'Book', action: 'search' }) && (
        <div className='row pad-btm'>
          <Form onSubmit={handleSubmit(onFormSubmit)} className='col-xs-12 col-sm-10 col-sm-offset-1 pad-hor'>
            <Form.Group className={errors.search ? 'has-warning' : ''}>
              <div className='input-group mar-btm'>
                <input
                  name='search'
                  ref={register({ required: true })}
                  type='text'
                  placeholder='Buscar...'
                  defaultValue={search}
                  className='form-control input-lg'
                />
                <span className='input-group-btn'>
                  <Button size='lg' as='input' type='submit' value='Buscar' />
                </span>
              </div>
              {errors.search && (
                <label className='control-label'>Ingresa al menos 1 caracteres para realizar una busqueda</label>
              )}
            </Form.Group>
          </Form>
        </div>
      )}

      <div className='panel'>
        <div className='panel-body'>
          <Row>
            {
              // TODO is this legacy?
              //   <Col md={12}>
              //   <label className='control-label'>Buscar en:</label>
              //   <div className='radio'>
              //     <input
              //       id='all'
              //       className='magic-radio'
              //       type='radio'
              //       checked={searchFilter == 'all'}
              //       onChange={() => handleSearchFilter('all')}
              //       onMouseDown={handleMouseDown}
              //     />
              //     <label htmlFor='all' className='col-md-2'>
              //       TODO
              //     </label>
              //     <input
              //       id='actas'
              //       className='magic-radio'
              //       type='radio'
              //       checked={searchFilter == 'actas'}
              //       onChange={() => handleSearchFilter('actas')}
              //       onMouseDown={handleMouseDown}
              //     />
              //     <label htmlFor='actas' className='col-md-2'>
              //       ACTAS
              //     </label>
              //     <input
              //       id='apendices'
              //       className='magic-radio'
              //       type='radio'
              //       checked={searchFilter == 'apendices'}
              //       onChange={() => handleSearchFilter('apendices')}
              //       onMouseDown={handleMouseDown}
              //     />
              //     <label htmlFor='apendices' className='col-md-2'>
              //       APENDICES
              //     </label>
              //   </div>
              // </Col>
            }
          </Row>
          {search.trim().length > 0 && (
            <div className='pad-hor mar-top pad-btm'>
              <Row>
                <div className='col-sm-11'>
                  <h2 className='text-thin mar-no'>
                    Se encontró {totalBookPages} resultado{totalBookPages > 1 ? 's' : ''} para:
                    <i className='text-info text-normal'> &ldquo;{search}&ldquo;</i>
                  </h2>
                </div>
                <div className='col-sm-1'>
                  <Button onClick={handleClearSearch}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </div>
              </Row>
              <hr />
            </div>
          )}
          {loading && (
            <div className='text-center'>
              <Loader type='TailSpin' color='#00BFFF' />
            </div>
          )}
          {!loading && (
            <Row>
              {bookPages.map((page, index) => (
                <Page
                  key={index}
                  index={index}
                  bookName={page.bookName}
                  pageNumber={page.pageNumber}
                  coincidencesExact={page.coincidencesExact.length}
                  coincidencesByWord={page.coincidencesByWord.length}
                />
              ))}
            </Row>
          )}
          <Row>
            <Pagination
              totalRecords={totalBookPages}
              pageLimit={perPage}
              currentPage={page}
              changePage={handlePageChange}
              changePageLimit={handlePerPage}
            />
          </Row>
        </div>
      </div>
    </Fragment>
  )
}

export default Home
