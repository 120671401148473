import axios from 'axios'
import { environment } from 'Enviroments'
import SecureLS from 'secure-ls'

const ls = new SecureLS({ encodingType: 'aes' })

const baseURL = environment.url

const API = axios.create({
  baseURL,
  timeout: 500000,
})

API.interceptors.request.use(
  config => {
    const AUTH_TOKEN = ls.get('token')
    if (AUTH_TOKEN) {
      config.headers['Authorization'] = JSON.parse(AUTH_TOKEN)
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    Promise.reject(error)
  },
)

API.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    if (error.response) {
      const status = error.response.status
      if (status === 401) {
        ls.removeAll()
      }
      return Promise.reject(error.response.data)
    } else {
      return Promise.reject({ status: 500, message: 'Error de conexión con el servidor.' })
    }
  },
)

export default API
