import Api from 'api/Api'
import { IFolder } from 'models/Folder'
import { IPage } from 'models/Page'
import SecureLS from 'secure-ls'
import Document from '../models/Document'

const resource = '/library'
const ls = new SecureLS({ encodingType: 'aes' })

export default class LibraryService {
  static async get(
    path: string,
    limit: number,
    offset: number,
  ): Promise<{
    folders: IFolder[]
    pages: IPage[]
    total: number
  }> {
    let url = `${resource}`
    url += `?path=${path}&limit=${limit}&offset=${offset}`
    const { data } = await Api.get(url)
    return data
  }

  static async getImageByKey(key: string): Promise<IPage> {
    const { data } = await Api.get(`${resource}/page?key=${key}`)
    const { page } = data
    return page
  }

  static async countPagesLocation(location: string): Promise<number> {
    const { data } = await Api.get(`${resource}/pages/count?location=${location}`)
    const { total } = data
    return total
  }

  static async getPending(): Promise<Array<IFolder>> {
    const { data } = await Api.get(`${resource}/pending`)
    return data
  }

  static async getThumbnails(id: number): Promise<IPage[]> {
    const { data } = await Api.get(`${resource}/thumbnails/${id}`)
    const { thumbnails } = data
    return thumbnails
  }

  static async getNextThumbnails(id: number): Promise<IPage[]> {
    const { data } = await Api.get(`${resource}/thumbnails/next/${id}`)
    const { thumbnails } = data
    return thumbnails
  }

  static async getPrevThumbnails(id: number): Promise<IPage[]> {
    const { data } = await Api.get(`${resource}/thumbnails/prev/${id}`)
    const { thumbnails } = data
    return thumbnails
  }

  static async getNextImage(id: number): Promise<IPage | undefined> {
    const { data } = await Api.get(`${resource}/image/next/${id}`)
    const { image } = data
    return image
  }

  static async getPrevImage(id: number): Promise<IPage | undefined> {
    const { data } = await Api.get(`${resource}/image/prev/${id}`)
    const { image } = data
    return image
  }

  static async fileManager(pathName: string, page: number, search = ''): Promise<any> {
    let url = `${resource}`
    if (pathName !== '') url += `?currentFolder=${pathName}&page=${page}${search ? `&search=${search.trim()}` : ''}`
    const { data } = await Api.get(url)
    return { pathName: data.pathName, files: data.files, pagination: data.pagination }
  }

  static async getFile(folder: string, fileName: string): Promise<string> {
    const { data } = await Api.get(`${resource}/show_file?folder=${folder}&fileName=${fileName}`)
    let base64 = 'data:image/jpg;base64,'
    base64 += Buffer.from(data.imageData.data).toString('base64')
    return base64
  }

  static async makeDir(folder: string): Promise<string> {
    await Api.post(`${resource}/make_dir`, {
      folder,
    })

    return ''
  }

  static async uploadFiles(files: FileList, path: string) {
    const formData = new FormData()
    for (let x = 0; x < files.length; x++) formData.append('files', files[x])
    formData.append('path', path)
    const AUTH_TOKEN = ls.get('token')
    await Api.post(`${resource}/upload_files`, formData, {
      headers: {
        Authorization: JSON.parse(AUTH_TOKEN),
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  static async search(search: string, path: string): Promise<Array<Document>> {
    let url = `${resource}/search`
    url += `?search=${search}&path=${path}`
    const { data } = await Api.get(url)
    return data.files
  }

  static async edit(id: number, name: string): Promise<string> {
    return await Api.put(`${resource}/${id}`, { name })
  }

  static async delete(id: number): Promise<string> {
    return await Api.delete(`${resource}/${id}`)
  }
}
