import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'

const NotFound = (): ReactElement => {
  const history = useHistory()

  const back = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    history.push('/')
  }

  return (
    <div className='text-center cls-content'>
      <p className='h4 text-uppercase text-bold'>Página No Encontrada!</p>
      <div className='pad-btm'>Lo sentimos, pero la página que busca no se ha encontrado.</div>
      <hr className='new-section-sm bord-no' />
      <div className='pad-top'>
        <a className='btn btn-primary' onClick={back}>
          Regresar
        </a>
      </div>
    </div>
  )
}

export default NotFound
