import React, { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/free-solid-svg-icons'
import { Col } from 'react-bootstrap'
import { IFolder } from 'models/Folder'

export function FolderBack({ onClick }: { onClick: () => void }) {
  return (
    <Col xs={12} sm={6} md={4} lg={3} xl={2} style={{ padding: 10 }}>
      <a onClick={onClick} className='file-details'>
        <div className='media-block text-center'>
          <div>
            <i>
              <FontAwesomeIcon icon={faFolder} size='4x' />
            </i>
          </div>
          <div className='media-body'>
            <p className='file-name'>..</p>
          </div>
        </div>
      </a>
    </Col>
  )
}

export function FolderElement({ folder, enterFolder }: { folder: IFolder; enterFolder: (folder: IFolder) => void }) {
  return (
    <Col xs={12} sm={6} md={4} lg={3} xl={2} style={{ padding: 10 }}>
      <a onClick={() => enterFolder(folder)} className='file-details'>
        <div className='media-block text-center'>
          <div>
            <i>
              <FontAwesomeIcon icon={faFolder} size='4x' />
            </i>
          </div>
          <div className='media-body'>
            <p className='file-name'>{folder.name}</p>
          </div>
        </div>
      </a>
    </Col>
  )
}
