import React, { useState, ReactNode, ReactElement } from 'react'
import { notify } from 'components/Toast/Toast'
import BookService from 'services/BookService'
import BookPage from 'models/BookPage'

export interface IBookContext {
  search: string
  searchFilter: string
  bookPages: Array<BookPage>
  page: number
  perPage: number
  totalBookPages: number
  updateBookPages: (searchTag: string, searchFilter: string, page: number, perPage: number) => void
  updatePage: (index: number, bookPage: BookPage) => void
  clearSearch: () => void
}

type BookProps = {
  children: ReactNode
}

export const BookContext = React.createContext<IBookContext | null>(null)

const BookProvider = ({ children }: BookProps): ReactElement => {
  const [bookPages, setBookPages] = useState(new Array<BookPage>(0))
  const [search, setSearch] = useState('')
  const [searchFilter, setSearchFilter] = useState('all')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalBookPages, setTotalBookPages] = useState(0)

  const updateBookPages = async (searchTag: string, searchFilter: string, page: number, perPage: number) => {
    try {
      if (searchTag.length > 0) {
        const data = await BookService.search(searchTag, searchFilter, page, perPage)
        setTotalBookPages(data.pagination.total_elements)
        setBookPages(data.bookPages)
        setSearch(searchTag)
        setSearchFilter(searchFilter)
        setPage(page)
        setPerPage(perPage)
      } else setSearchFilter(searchFilter)
    } catch (e) {
      console.log('error: ', e)
      if (e.error) notify('error', e.error)
    }
  }

  const updatePage = (index: number, bookPage: BookPage) => {
    const pages = [...bookPages]
    pages[index] = bookPage
    setBookPages(pages)
  }

  const clearSearch = () => {
    setSearch('')
    setBookPages(new Array<BookPage>(0))
    setPage(1)
    setPerPage(10)
    setTotalBookPages(0)
  }

  return (
    <BookContext.Provider
      value={{
        search,
        searchFilter,
        page,
        perPage,
        totalBookPages,
        bookPages,
        updateBookPages,
        updatePage,
        clearSearch,
      }}
    >
      {children}
    </BookContext.Provider>
  )
}

export default BookProvider
