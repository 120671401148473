import React, { ReactElement } from 'react'
import { Switch, useRouteMatch, matchPath, useLocation, Redirect } from 'react-router-dom'
import Routes from 'config/routes'
import PrivateRoute from 'components/PrivateRoute'
import BookProvider from 'context/bookContext'
import LibraryProvider from 'context/libraryContext'

const Main = (): ReactElement => {
  const location = useLocation()
  const isNotFound = useRouteMatch('/404')

  const switchRoutes = () => (
    <Switch>
      {Routes.map((route, key) => {
        if (route.isPrivate) {
          return <PrivateRoute key={key} path={route.path} exact component={route.component} />
        }
        return null
      })}
      <Redirect to='/404' />
    </Switch>
  )

  const makeBrand = () => {
    let brand = ''
    Routes.forEach(item => {
      const isMatch = matchPath(location.pathname, { path: item.path, exact: true })
      if (isMatch && item.name) {
        brand = item.name
        return
      }
    })
    return brand
  }

  return (
    <div id='content-container'>
      <div id='page-head'>
        {isNotFound ? (
          <div className='text-center cls-content'>
            <h1 className='error-code text-info'>404</h1>
          </div>
        ) : (
          <div id='page-title'>
            <h1 className='page-header text-overflow'>{makeBrand()}</h1>
          </div>
        )}
      </div>

      <div id='page-content'>
        <LibraryProvider>
          <BookProvider>{switchRoutes()}</BookProvider>
        </LibraryProvider>
      </div>
    </div>
  )
}

export default Main
