import React, { ReactElement, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Form, Col, Button, Row } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import UserService from 'services/UserService'
import DepartmentService from 'services/DepartmentService'
import User from 'models/User'
import UserPermission from 'models/UserPermission'

import { notify } from 'components/Toast/Toast'
import { IFolder } from 'models/Folder'

interface RouteParams {
  userId: string
}

const Departments = (): ReactElement => {
  const { register, handleSubmit, setValue } = useForm()

  const { userId } = useParams<RouteParams>()
  const [user, setUser] = useState<User | null>(null)
  const [departments, setDepartments] = useState<IFolder[]>([])
  const history = useHistory()

  useEffect(() => {
    getUser()
    getDepartments()
  }, [userId])

  const getUser = async () => {
    try {
      const user = await UserService.getUser(+userId)
      setUser(user)
    } catch (e) {
      if (e.error) notify('error', e.error)
      console.error(e)
    }
  }

  const getDepartments = async () => {
    try {
      const departments = await DepartmentService.get()
      setDepartments(departments)
    } catch (e) {
      if (e.error) notify('error', e.error)
      console.error(e)
    }
  }

  const onFormSubmit = async (values: { departments: boolean[] }) => {
    const depptForm = values.departments
    const choosedDepartments = []
    for (let i = 0; i < depptForm.length; i++) {
      if (depptForm[i]) {
        choosedDepartments.push(departments[i])
      }
    }

    try {
      await DepartmentService.assign(choosedDepartments, user ? user.id : -1)
      notify('', 'Guardado')
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Row>
      <div className='col-md-6 col-md-offset-3'>
        {user && (
          <div className='panel'>
            <div className='panel-heading'>
              <h3 className='panel-title'>{user.fullName()}</h3>
            </div>
            <Form onSubmit={handleSubmit(onFormSubmit)} className='form-horizontal form-padding'>
              <div className='panel-body'>
                {departments.map((department, i) => (
                  <Row key={`${i}departments`}>
                    <div className='col-md-1 col-md-offset-2'>
                      <Form.Check
                        id={`departments[${i}]`}
                        name={`departments[${i}]`}
                        ref={register}
                        defaultChecked={user.departments.find(el => el.folder.id == department.id) != null}
                      />
                    </div>

                    <div className='col-md-9'>{department.name}</div>
                  </Row>
                ))}
              </div>
              <div className='panel-footer text-right'>
                <Button variant='default' onClick={() => history.push('/users')}>
                  Cancelar
                </Button>
                &nbsp;
                <Button type='submit'>Actualizar Departamentos</Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </Row>
  )
}

export default Departments
