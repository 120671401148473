import React, { ReactElement, ReactNode } from 'react'
import { Button, Modal } from 'react-bootstrap'

type PropsModal = {
  children?: ReactNode
  title: string
  show: boolean
  handleClose: () => void
  onSave: () => void
}

const ConfirmModal = (props: PropsModal): ReactElement => {
  return (
    <Modal show={props.show} onHide={props.handleClose} backdrop='static' keyboard={false} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.handleClose}>
          Cancelar
        </Button>
        <Button variant='danger' onClick={props.onSave}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
