import React, { useRef, Fragment, ReactElement } from 'react'
import ReactToPrint from 'react-to-print'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import Canvas from './Canvas'
import 'assets/css/print.min.css'

import UserHistoryService from 'services/UserHistoryService'

interface PrintPageProps {
  imageData?: ImageData | null
  src?: string
  images?: Array<string>
  bookName: string
  page: number
}

const PrintPage = ({ imageData, bookName, page, src = '', images = [] }: PrintPageProps): ReactElement => {
  const printRef = useRef<HTMLDivElement>(null)

  const handleAfterPrint = () => savePrintActivity()

  const handleBeforePrint = () => console.log('print in onBeforePrint')

  const savePrintActivity = async () => {
    try {
      await UserHistoryService.saveUserActivity('printBook', 0, `Libro: ${bookName}, Pág.: ${page}`)
    } catch (e) {
      console.log('error in print: ', e)
    }
  }

  const printTrigger = () => {
    return (
      <Button className='btn-labeled'>
        <i className='btn-label'>
          <FontAwesomeIcon icon={faPrint} size='lg' />
        </i>
        Imprimir
      </Button>
    )
  }

  return (
    <Fragment>
      <ReactToPrint
        content={() => printRef.current}
        onAfterPrint={handleAfterPrint}
        onBeforePrint={handleBeforePrint}
        removeAfterPrint
        trigger={printTrigger}
      />
      <div style={{ display: 'none' }}>
        <div ref={printRef}>
          {src && <img src={src} style={{ width: '100%', height: '100vh' }} />}
          {imageData && <Canvas dataImage={imageData} />}
          {images.length ? (
            images.map((image, key) => {
              return <img key={key} src={image} style={{ width: '100%', height: '100vh' }} />
            })
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default PrintPage
